

class ConstValue{
    constructor(values){
        this.values = values;
    }
}

const FIXED = 1,
    KEY = 2,
    MERGE = 3,
    PATTEN = 4,
    MERGE_PATTEN = 5,
    SYNONYM = 6,
    DEFAULT = 7,
    MIN_OF_LIST = 8,
    MAX_OF_LIST = 9,
    FIRST_OF_LIST = 10,
    EXTEND = 11,
    LIST_INDEX = 12,
    LIST_VALUE = 13,

    IGNORE = 99;

const RuleType = const_value({
    NONE:0,
    FIXED:1,
    KEY:2,
    MERGE:3,
    PATTEN:4,
    MERGE_PATTEN:5,
    SYNONYM:6,
    DEFAULT:7,
    MIN_OF_LIST:8,
    MAX_OF_LIST:9,
    FIRST_OF_LIST:10,
    EXTEND:11,
    LIST_INDEX:12,
    LIST_VALUE:13,
    UPLOAD_FILE:14,
    WHOLE_DOCUMENT:15,

    IGNORE:99
});

const SourceType = const_value({
    NORMAL:1,
    IMAGE:2,
    IMAGE_TAG:3,
    HTML:4,
    IMAGE_SPLIT:5,
    TEMPLATE:6,
    SIZE_TEMPLATE:7,
    FARE_TEMPLATE:8,
    IMAGE_UPLOAD:9,
    IMAGE_TRANSPARENT:11,
    IMAGE_SOURCE_UPLOAD:12,
    IMAGE_TAG_UPLOAD:13
});

const MappingType = const_value({
    NONE:0,
    FIELD_DEFAULT:1,
    FIXED:2,
    MAPPING_RULE:3
});

const ImageResizeType = const_value({
    NONE:0,
    WIDTH_FIXED:1,
    HEIGHT_FIXED:2,
    BOTH_FIXED:3,
    SCALE:4
});

const FieldGroup = const_value({
    PRODUCT_INFO:0,
    PRICE_LOGITICS:1,
    PRODUCT_DETAILS:2,
    AFTERSALE:3,
    NONE:9
});
function const_value(list){
    var singleton = new ConstValue(list);
    $.each(list,(const_key,value)=>{
        Object.defineProperty(singleton,const_key,{
            get: function(){
                return value;
            }
        });
    });
    return singleton;
}