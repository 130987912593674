function error(){
    console.error.apply(console,arguments);
}

function info(){
    console.log.apply(console,arguments);
}

function warning(){
    console.warn.apply(console,arguments);
}

function mkmap(list,column){
    let map = new Map();
    $.each(list,(k,o) => map.set(o[column],o));
    return map;
}

function dpclone(obj){
    return JSON.parse(JSON.stringify(obj));
}

function groupby(list,key){
    var ret = new Map();
    list.forEach(item=>ret.has(item[key])?ret.get(item[key]).push(item):ret.set(item[key],[item]));
    return ret;
}

/**
* 根据传入的列表生成树状结构，约定当parent_id为0时，该节点为根节点
* @param Array list 元素数组
* @param string id_attr_name 保存元素id的字段名称
* @param string parent_attr_name 保存元素parent_id的字段名称
* @param string children_attr_name 保存元素子节点列表的字段名称
**/
function mktree(list,id_attr_name = 'id',parent_attr_name = 'parent_id',children_attr_name = 'children'){
    var map = mkmap(list,id_attr_name);
    var tree = [];
    list.forEach(v=>{
        if(v[parent_attr_name] == 0){
            tree.push(v);
        }else{
            var parent = map.get(v[parent_attr_name]);
            if(!parent[children_attr_name]){
                parent[children_attr_name] = [];
            }
            parent[children_attr_name].push(v);
        }
    });
    return tree;
}

function flip(list){
    let o = {};
    $.each(list,(k,v) => o[v] = k);
    return o;
}

function parseJSON(json_str,def = {},show_warning = 0,warning = ''){
    try{
        var json = JSON.parse(json_str);
        if(!json || typeof(json) != 'object'){
            throw new Error();
        }
        return json;
    }catch(err){
        if(show_warning){
            console.warn(warning);
        }
        return def;
    }
}

var mk_unique_id;
{
    let count = 0;
    mk_unique_id = function(){
        return count++;
    }
}

function sblen(str){
    return str.replace(/[^\x00-\xff]/g,"**").length;
}

function cutsb(pStr,pLen){

    // 原字符串长度
    var _strLen = sblen(pStr);

    if(_strLen <= pLen) return pStr;

    var _cutString = '';

    var _lenCount = 0;

    for (var i = 0; i < pStr.length ; i++ ) {
        if (isFull(pStr.charAt(i))) {
            _lenCount += 2;
        } else {
            _lenCount += 1;
        }
        if(_lenCount > pLen){
            break;
        }
        _cutString += pStr[i];
    }

    return _cutString;
}

/*
 * 判断是否为全角
 *
 * pChar:长度为1的字符串
 * return: true:全角
 *          false:半角
 */
function isFull (pChar) {
    if ((pChar.charCodeAt(0) > 128)) {
        return true;
    } else {
        return false;
    }
}

function isSimple(type){
    return type == 'input' ||
            type == 'multiInput' ||
            type == 'label' ||
            type == 'singleCheck' ||
            type == 'multiCheck';
}

function csrf_token(){
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
}

Array.prototype.unique = function(){
    var res = [];
    var json = {};
    for(var i = 0; i < this.length; i++){
        if(!json[this[i]]){
            res.push(this[i]);
            json[this[i]] = 1;
        }
    }
    return res;
}

csrf_token();
function toAjax(options){
    let dom = $('<div></div>');
    let url = options.url,
        data = options.data,
        method = options.method;
        LOADING.show();
    $.ajax({
        url,
        data,
        type:method,
        dataType:"json",
        success:function(result){
            if(result.code == 1){
                dom.trigger('pie.ajax-success',result.data);
            }else{
                dom.trigger('pie.ajax-failed',result.message);
            }

        },
        error:function(xmlHttpRequest,message,error){
            if(xmlHttpRequest.status == 0){
                alert('页面已失效，请刷新');
            }else{
                alert('服务器错误：' + xmlHttpRequest.status);
                dom.trigger('pie.ajax-error',xmlHttpRequest,message,error);
            }
        },
        complete:function(xmlHttpRequest,ts){
            LOADING.hide();
            dom.trigger('pie.ajax-complete',xmlHttpRequest,ts);
        }
    });

    return {
        on:function(){
            dom.on.apply(dom,arguments);
            return this;
        },
        off:function(){
            dom.off.apply(dom,arguments);
            return this;
        },
        one:function(){
            dom.off.apply(dom,arguments);
            return this;
        }
    };
}

function goPage(link,params = {},blank=0){
    var parser = new UrlParser(link);
    parser.setParams(params);
    info(parser.url());
    if(blank){
        window.open(parser.url());
    }else{
        location.href=parser.url();
    }
}

var UrlParser=(function(){
    var objURL=function(url){
        this.ourl=url||window.location.href;
        this.href="";//?前面部分
        this.params={};//url参数对象
        this.jing="";//#及后面部分
        this.init();
    }
    //分析url,得到?前面存入this.href,参数解析为this.params对象，#号及后面存入this.jing
    objURL.prototype.init=function(){
        var str=this.ourl;
        var index=str.indexOf("#");
        if(index>0){
            this.jing=str.substr(index);
            str=str.substring(0,index);
        }
        index=str.indexOf("?");
        if(index>0){
            this.href=str.substring(0,index);
            str=str.substr(index+1);
            var parts=str.split("&");
            for(var i=0;i<parts.length;i++){
                var kv=parts[i].split("=");
                this.params[kv[0]]=kv[1];
            }
        }
        else{
            this.href=this.ourl;
            this.params={};
        }
    }
    //只是修改this.params
    objURL.prototype.set=function(key,val){
        this.params[key]=val;
    };

    objURL.prototype.setParams = function(o){
        Object.assign(this.params,o);
    };

    //只是设置this.params
    objURL.prototype.remove=function(key){
        this.params[key]=undefined;
    };
    //根据三部分组成操作后的url
    objURL.prototype.url=function(){
        var strurl=this.href;
        var objps=[];//这里用数组组织,再做join操作
        for(var k in this.params){
            if(this.params[k]){
                objps.push(k+"="+this.params[k]);
            }
        }
        if(objps.length>0){
            strurl+="?"+objps.join("&");
        }
        if(this.jing.length>0){
            strurl+=this.jing;
        }
        return strurl;
    };
    //得到参数值
    objURL.prototype.get=function(key){
        return this.params[key];
    };
    return objURL;
})();

function isSimpleField(type){
    return type == 'input' || type == 'label' || type == 'multiInput' || type == 'singleCheck' || type == 'multiCheck';
}

const FIELD_TYPE = {
    visible(field){
        return field.data_rule_type != RuleType.IGNORE;
    }
};
const SOURCE_CHECK={
    is_normal(type){
        return type==SourceType.NORMAL;
    },
    is_image(type){
        return type==SourceType.IMAGE;
    },
    is_image_tag(type){
        return type==SourceType.IMAGE_TAG;
    },
    is_html(type){
        return type == SourceType.HTML;
    },
    is_image_split(type){
        return type==SourceType.IMAGE_SPLIT;
    },
    is_template(type){
        return type==SourceType.TEMPLATE;
    },
    is_size_template(type){
        return type == SourceType.SIZE_TEMPLATE;
    },
    is_fare_template(type){
        return type == SourceType.FARE_TEMPLATE;
    },
    is_image_upload(type){
        return type == SourceType.IMAGE_UPLOAD;
    },
    is_splice(type){
        return type == SourceType.SPLICE;
    },
    is_transparent(type){
        return type == SourceType.IMAGE_TRANSPARENT;
    },
    is_image_source_upload(type){
        return type == SourceType.IMAGE_SOURCE_UPLOAD
    },
    is_image_tag_upload(type){
        return type == SourceType.IMAGE_TAG_UPLOAD
    }
}

const LOADING = {
    _loading:false,
    show(){
        this._loading = true;
        $('.loader-back').removeClass('hidden');
        $('.loader').removeClass('hidden');
        $('body').addClass('lock-screen');
    },
    hide(){
        this._loading = false;
        $('.loader-back').addClass('hidden');
        $('.loader').addClass('hidden');
        $('body').removeClass('lock-screen');
    }
};

const DATA_RULE_CHECKER = {
    is_none(type){
        return type == RuleType.NONE;
    },
    is_ignore(type){
        return type == RuleType.IGNORE;
    },
    is_rule_visible(type){
        return !this.is_none(type) && !this.is_ignore(type);
    }
};

//反转义HTML
function HTMLDecode(text) {
    var temp = document.createElement("div"); 
    temp.innerHTML = text; 
    var output = temp.innerText || temp.textContent; 
    temp = null; 
    return output; 
}

//将全角转成半角
function toSBC(str){
    var result = "";
    var len = str.length;
    for(var i=0;i<len;i++)
    {
        var cCode = str.charCodeAt(i);
        //全角与半角相差（除空格外）：65248（十进制）
        cCode = (cCode>=0xFF01 && cCode<=0xFF5E)?(cCode - 65248) : cCode;
        //处理空格
        cCode = (cCode==0x03000)?0x0020:cCode;
        result += String.fromCharCode(cCode);
    }
    return result;
}

//判读是否为颜色分类扩展
function is_color_extend(name){
    if(name == 'prop_extend_1627207' || name == 'sale_extend_prop_1627207' || name == 'prop_extend_122276380') return true;
    return false;
}

//判断是否为尺码扩展
function is_size_extend(name){
    if(name == 'prop_extend_20549' || name == 'prop_extend_20509' || name == 'prop_extend_122216343' || name == 'std_size_extends_20509' || name == 'std_size_extends_20518' || name == 'std_size_extends_20549') return true;
    return false;
}

//判断是否为颜色或者颜色分类
function is_color(name){
    if(name == '颜色分类' || name == '颜色') return true;
    return false;
}

//判断是否为尺码或者自定义‘尺码’
function is_size(name){
    if(name == '尺码' || name == '“自定义”尺码') return true;
    return false;
}

//判断是否为商品描述字段
function is_item_desc(field){
    if(field.name=='商品描述'||field.name=='优惠信息内容'||field.name=='商品参数内容'||field.name=='视频展示内容'||field.name=='模特图内容'||field.name=='商品展示图内容'||field.name=='功能展示内容'||field.name=='细节图内容'||field.name=='尺码说明内容'||field.name=='使用说明内容'||field.name=='建议搭配内容'||field.name=='售后内容'||field.name=='促销专区内容'||field.name=='商品实拍内容'||field.name=='商品尺码表内容'||field.name=='商品细节内容'||field.name=='品牌介绍内容'||field.name=='包装图示内容'||field.name=='物流快递内容') return true;
    return false;
}